html {
  --scroll-behavior: smooth!important;
  scroll-behavior: smooth!important;
}


.animate-float {
  animation: float-full-height 4s infinite ease-in-out;
}
.animate-float-slow {
  animation: float-full-height 6s infinite ease-in-out;
}
.animate-float-very-slow {
  animation: float-half-height 8s infinite ease-in-out;
}

@keyframes float-full-height {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
@keyframes float-half-height {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
}

.lp-pricing-gradient {
  background: rgb(251, 173, 36);
  background: -moz-radial-gradient(
    circle,
    rgba(251, 173, 36, 1) 0%,
    rgba(251, 173, 36, 1) 0%,
    rgba(0, 0, 0, 0) 69%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(251, 173, 36, 1) 0%,
    rgba(251, 173, 36, 1) 0%,
    rgba(0, 0, 0, 0) 69%
  );
  background: radial-gradient(
    circle,
    rgba(251, 173, 36, 1) 0%,
    rgba(251, 173, 36, 1) 0%,
    rgba(0, 0, 0, 0) 69%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbad24",endColorstr="#000000",GradientType=1);
}

/* Scrollbar */
/* width */
.customScrollBar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: rgb(161 161 170);
  border-radius: 5px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: rgb(113 113 122);
}

.intercom-lightweight-app   {
  z-index: 0 !important;
}

.intercom-app *   {
  z-index: 0 !important;
}

.intercom-messenger-frame {
  z-index: 100 !important;
}